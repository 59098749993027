import * as React from "react";

//import './index.css';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Divider, Tooltip, Space } from 'antd';

import RouteView, { IRouteViewProps } from "../routes/RouteView";
import { History } from "history";

// import { Button } from 'antd';


interface ILayoutProps extends IRouteViewProps {
  history: History;
}






//总页面布局
const Layout = (props: ILayoutProps) => {
  const handleClick = React.useCallback((e) => {
    const { name } = e.target;
    props.history.push(name);
  }, [props.history]);

  return (
    <div>
      <div>
        <Button shape="circle" icon={<SearchOutlined />} />
        <button  name="/basic/page1" onClick={handleClick}>
          支付宝红包
        </button>
        <Divider type="vertical" />
        <button  name="/basic/page2" onClick={handleClick}>
          信用卡
        </button>

        {/*<button name="/basic/page3" onClick={handleClick}>*/}
        {/*  Page3*/}
        {/*</button>*/}
      </div>
      <RouteView {...props} />
    </div>
  );
};

export default Layout;
